//useAuthToken.js
import { useState, useEffect, useCallback } from "react";
import { requestToken } from "./AuthServices";
import { useIsAuthenticated } from "@azure/msal-react";
import { useNavigate } from "react-router";

const useAuthToken = () => {
  const [token, setToken] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const isAuthenticated = useIsAuthenticated();
  const navigate = useNavigate();

  //  useCallBack agar fetchToken tidak didefinisikan ulang di setiap render.
  const fetchToken = useCallback(async () => {
    setIsLoading(true);
    try {
      const accessToken = await requestToken();
      if (!accessToken) {
        console.warn("Token acquisition failed, staying on the page.");
      } else {
        setToken(accessToken);
      }
    } catch (error) {
      console.error("Failed to acquire token:", error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    // if (!isAuthenticated) {
    //   navigate("/");
    //   return;
    // }
    fetchToken();
  }, [isAuthenticated, fetchToken]); //update token baru

  return { token, isLoading };
};

export default useAuthToken;
