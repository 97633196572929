//AuthSerive.js
import { loginRequest } from "../config/api-config";
import { msalConfig } from "../config/api-config";
import { PublicClientApplication } from "@azure/msal-browser";

const msalInstance = new PublicClientApplication(msalConfig);

//console.log("cek isi MSAL Instance:", msalInstance);

export const requestToken = async () => {
  const accounts = msalInstance.getAllAccounts();
  if (accounts.length === 0) {
    console.warn("No accounts found");
    return null;
  }

  const request = {
    ...loginRequest,
    account: accounts[0],
  };

  try {
    const responseToken = await msalInstance.acquireTokenSilent(request);
    return responseToken.accessToken;
  } catch (error) {
    console.warn("Failed to acquire token silently:", error);

    try {
      const interactiveResponse = await msalInstance.acquireTokenPopup(request);
      return interactiveResponse.accessToken;
    } catch (interactiveError) {
      console.error("Interactive token acquisition failed:", interactiveError);
      throw new Error("Token acquisition failed");
    }
  }
};
