import {
  Collapse,
  Form,
  Input,
  Row,
  Col,
  InputNumber,
  Radio,
  Button,
  Affix,
  Image,
  Select,
  message,
  TimePicker,
  DatePicker,
  AutoComplete,
} from "antd";

import { useNavigate, useParams } from "react-router";
import { Block, Confirm, Report } from "notiflix";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import HeaderFarms from "../../../utils/header_farms";
import Footer from "../../../atoms/footer";
import Navbar from "../../../atoms/navbar";
import API from "../../../services";
import "./styles.css";

import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { loginRequest } from "../../../config/api-config";
import {
  CheckOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import moment from "moment";

function RequestFuelAdjustment() {
  const [form] = Form.useForm();
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const { Option } = Select;
  const { Panel } = Collapse;

  const [RequestDetail, setRequestDetail] = useState(false);
  const [user, setUser] = useState(null);
  const [Location, setLocation] = useState([]);
  const [Operators, setOperators] = useState([]);
  const [Equipments, setEquipments] = useState([]);
  const [currentOperator, setCurrentOperator] = useState(null);
  const [EquipmentsActivity, setEquipmentActivity] = useState([]);

  let navigate = useNavigate();
  let { id } = useParams();

  const getDetailRequest = async (token) => {
    try {
      const res = await API.ViewRequestFuel(HeaderFarms(token), id);

      Block.remove(".ant-collapse");

      res.data.date = moment(res.data.date);
      res.data.time = moment(res.data.time);

      res.data.amount_adjustment = 0;
      res.data.record_id_adjustment = res.data.record_id;
      setCurrentOperator(res.data.operator_id.split(" - ")[0]);

      form.setFieldsValue(res.data);

      setRequestDetail(res.data);

      // only one call
      user || getUser(token);
    } catch (err) {
      console.error(err);
      Report.info("Working Permit", "Sorry, Fuel Request not found", "Okay");
      Block.remove(".ant-collapse");
    }
  };

  function RequestToken() {
    Block.hourglass(".ant-collapse");

    const request = {
      ...loginRequest,
      account: accounts[0],
    };

    instance
      .acquireTokenSilent(request)
      .then((response) => {
        const token = response.accessToken;
        getEquipmentActivity(token);
        getDetailRequest(token);
        getEquipments(token);
        getOperator(token);
        getArea(token);
      })
      .catch((e) => {
        // handleLogin(instance)
        console.error(e);
      });
  }

  const getArea = async (token) => {
    try {
      const res = await API.JobLocation(HeaderFarms(token), null);
      const uniqueLocations = [
        ...new Set(res.data.map((area) => area.location)),
      ];
      setLocation(uniqueLocations);
    } catch (err) {
      console.error(err);
      Report.warning(
        "Oops.. something wrong",
        "Sorry, this application is experiencing problems",
        "Okay"
      );
    }
  };

  const getEquipmentActivity = (token) => {
    API.EquipmentActivity(HeaderFarms(token), null)
      .then((res) => {
        setEquipmentActivity(res.data);
      })
      .catch((err) => {
        console.error(err);
        Report.warning(
          "Oops.. something wrong",
          "Sorry, this application is experiencing problems",
          "Okay"
        );
      });
  };

  const getEquipments = (token) => {
    API.Equipments(HeaderFarms(token), null)
      .then((res) => {
        setEquipments(res.data);
      })
      .catch((err) => {
        console.error(err);
        Report.warning(
          "Oops.. something wrong",
          "Sorry, this application is experiencing problems",
          "Okay"
        );
      });
  };

  const getOperator = (token) => {
    API.Operators(HeaderFarms(token), null)
      .then((res) => {
        setOperators(res.data);
      })
      .catch((err) => {
        console.error(err);
        Report.warning(
          "Oops.. something wrong",
          "Sorry, this application is experiencing problems",
          "Okay"
        );
      });
  };

  const handleFillEquipment = (equipmentSelected) => {
    let equipmentFilter = Equipments.find(
      (equipment) => equipment.identity_number === equipmentSelected
    );
    form.setFieldsValue(equipmentFilter);
  };

  function handleLogin(instance) {
    instance
      .loginRedirect(loginRequest)
      .then((_) => {})
      .catch((e) => {
        console.error(e);
      });
  }

  async function getUser(token) {
    try {
      let user = await API.getUserCredential(HeaderFarms(token), {
        access_token: token,
      });
      setUser(user);
    } catch (error) {
      console.error(error);
    }
  }

  const onFinishFailed = () => {
    message.warning("Mohon lengkapi formulir.");
  };

  const submitAdjustment = (data) => {
    Confirm.show("Important Confirmation", "Are you sure?", "Yes", "No", () => {
      const request = {
        ...loginRequest,
        account: accounts[0],
      };

      instance
        .acquireTokenSilent(request)
        .then((responseToken) => {
          data.operator_id =
            currentOperator.employee_id || data.operator_id.split(" - ")[0];
          // Send FormData to server
          API.AdjustmentRequestFuel(
            HeaderFarms(responseToken.accessToken),
            data
          )
            .then((_) => {
              Report.success(
                `Has been updated.`,
                "Berhasil di sesuaikan",
                "Okay",
                function () {
                  navigate("/fms/menu");
                }
              );
            })
            .catch((err) => {
              console.error(err);
              Report.warning(
                "Oops.. something wrong",
                "Sorry, this application is experiencing problems",
                "Okay"
              );
            });
        })
        .catch((e) => {
          // Handle error
        });
    });
  };

  const handleAmountSubmittedChange = (value) => {
    form.setFieldsValue({
      amount_issued: value,
      amount_submitted: value,
    });
  };

  useEffect(() => {
    // Check already login or not ?
    if (!isAuthenticated) {
      RequestToken();
    } else {
      if (instance.getAllAccounts().length > 0) {
        instance.setActiveAccount(accounts[0]);
        RequestToken();
      } else {
        handleLogin(instance);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  return (
    <>
      <Navbar
        title="View"
        back={true}
        subtitle="Fuel System"
        navigate={"/fms/request/view/" + id}
      ></Navbar>
      <div className="container-fluid white-back-cr">
        <Form
          form={form}
          name="fuel_request"
          initialValues={{
            remember: true,
          }}
          onFinish={submitAdjustment}
          onFinishFailed={onFinishFailed}
          encType="multipart/form-data"
          autoComplete="off"
          size="middle"
          layout="vertical"
        >
          <div className="container-fluid p-2" style={{ alignSelf: "center" }}>
            <div className="row">
              <div className="col-md-12">
                <Collapse
                  className="collapse_sr"
                  expandIconPosition={"end"}
                  accordion={false}
                  bordered={true}
                  defaultActiveKey={["0", "1", "2", "3", "4", "5"]}
                >
                  <Panel
                    style={{ background: "#fce8b6" }}
                    header={<b>Adjustment Request Fuel</b>}
                    key="2"
                  >
                    <Row gutter={20}>
                      <Col xs={{ span: 24 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                        <Form.Item
                          label={
                            <span
                              style={{ fontSize: "16px", fontWeight: "bold" }}
                            >
                              Date
                            </span>
                          }
                          name="date"
                          rules={[
                            {
                              required: true,
                              message: "Please input date",
                            },
                          ]}
                        >
                          <DatePicker
                            style={{ pointerEvents: "none" }}
                            showTime
                            format="YYYY-MM-DD"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 5 }} xl={{ span: 5 }}>
                        <Form.Item
                          label={
                            <span
                              style={{ fontSize: "16px", fontWeight: "bold" }}
                            >
                              Time
                            </span>
                          }
                          name="time"
                          rules={[
                            {
                              required: true,
                              message: "Please input time",
                            },
                          ]}
                        >
                          <TimePicker
                            style={{ pointerEvents: "none" }}
                            showNow={false}
                            format="HH:mm"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 12 }} sm={{ span: 8 }} xl={{ span: 3 }}>
                        <Form.Item
                          label={
                            <span
                              style={{ fontSize: "16px", fontWeight: "bold" }}
                            >
                              Shift
                            </span>
                          }
                          name="shift"
                          rules={[
                            {
                              required: true,
                              message: "Please select shift",
                            },
                          ]}
                        >
                          <AutoComplete
                            placeholder="Select"
                            allowClear
                            options={["SIANG", "MALAM"].map((item) => ({
                              value: item,
                            }))}
                            filterOption={(inputValue, option) => {
                              return (
                                option.value
                                  .toUpperCase()
                                  .indexOf(inputValue.toUpperCase()) !== -1
                              );
                            }}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={20}>
                      <Form.Item
                        style={{ display: "none" }}
                        label=""
                        name="record_id_adjustment"
                        rules={[
                          {
                            required: false,
                            message: "Wajib Isi",
                          },
                        ]}
                      >
                        <Input hidden />
                      </Form.Item>
                      <Col xs={{ span: 24 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                        <Form.Item
                          label="Fuel Truck"
                          name="fuel_truck"
                          rules={[
                            {
                              required: true,
                              message: "Wajib Isi",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select"
                            allowClear
                            filterOption={(inputValue, option) => {
                              return (
                                option.children
                                  .toUpperCase()
                                  .indexOf(inputValue.toUpperCase()) !== -1
                              );
                            }}
                          >
                            {[
                              "TS-9657-MMS",
                              "FT-002",
                              "FT-004",
                              "FT-1003-CPM",
                              "Tangki A",
                              "Tangki B",
                              "TS-9658",
                            ].map((item) => (
                              <Option key={item} value={item}>
                                {item}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={20}>
                      <Col xs={{ span: 24 }} sm={{ span: 8 }} xl={{ span: 8 }}>
                        <Form.Item
                          label="Lokasi edit"
                          name="location"
                          rules={[
                            {
                              required: true,
                              message: "Wajib Isi",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Pilih Lokasi"
                            allowClear
                            showSearch
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            suffixIcon={
                              Location.length > 0 ? (
                                <CheckCircleOutlined
                                  style={{ color: "green", fontSize: 18 }}
                                />
                              ) : (
                                <CloseCircleOutlined
                                  style={{ color: "red", fontSize: 18 }}
                                />
                              )
                            }
                          >
                            {Location.map((item, index) => (
                              <Select.Option key={index} value={item}>
                                {item}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={20}>
                      <Col xs={{ span: 24 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                        <Form.Item
                          label="Nomor Lambung"
                          name="hull_number"
                          rules={[
                            {
                              required: true,
                              message: "Wajib Isi",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select"
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            onChange={handleFillEquipment}
                          >
                            {Equipments.map((item) => (
                              <Select.Option
                                key={item.identity_number}
                                value={item.identity_number}
                              >
                                {item.identity_number}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 8 }} xl={{ span: 8 }}>
                        <Form.Item
                          label="Nama alat berat"
                          name="equipment_name"
                          rules={[
                            {
                              required: true,
                              message: "Wajib Isi",
                            },
                          ]}
                        >
                          <Input readOnly />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 8 }} xl={{ span: 8 }}>
                        <Form.Item
                          label="Owner"
                          name="equipment_owner"
                          rules={[
                            {
                              required: false,
                              message: "Wajib Isi",
                            },
                          ]}
                        >
                          <Input readOnly />
                        </Form.Item>
                        <Form.Item
                          style={{ display: "none" }}
                          label=""
                          name="category"
                          rules={[
                            {
                              required: false,
                              message: "Wajib Isi",
                            },
                          ]}
                        >
                          <Input hidden />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={20}>
                      <Col xs={{ span: 24 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                        <Form.Item
                          label="Kilometer"
                          name="kilometer"
                          rules={[
                            {
                              required: false,
                              message: "Wajib Isi",
                            },
                          ]}
                        >
                          <InputNumber
                            type={"number"}
                            step={0.1}
                            addonAfter="KM"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 5 }} xl={{ span: 5 }}>
                        <Form.Item
                          label="Hourmeter"
                          name="hourmeter"
                          rules={[
                            {
                              required: false,
                              message: "Wajib Isi",
                            },
                          ]}
                        >
                          <InputNumber
                            type={"number"}
                            step={0.1}
                            addonAfter="HM"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={20}>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 10 }}
                        xl={{ span: 10 }}
                      >
                        <Form.Item
                          label={
                            <span
                              style={{ fontSize: "16px", fontWeight: "bold" }}
                            >
                              Aktifitas Unit
                            </span>
                          }
                          name="segment_code"
                          rules={[
                            {
                              required: true,
                              message: "Wajib Isi",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Pilih Aktifitas"
                            allowClear
                            showSearch
                            filterOption={(input, option) =>
                              option.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {EquipmentsActivity.map((item, index) => (
                              <Select.Option
                                key={index}
                                value={item.segment_code}
                              >
                                {item.segment_name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={20}>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 10 }}
                        xl={{ span: 10 }}
                      >
                        <Form.Item
                          label="Nama Supir / Operator"
                          name="operator_id"
                          rules={[
                            {
                              required: true,
                              message: "Wajib Isi",
                            },
                          ]}
                        >
                          <Select
                            placeholder="Select"
                            allowClear
                            showSearch
                            optionFilterProp="children"
                            // ref={operatorInputRef}
                            clearIcon={false}
                            suffixIcon={
                              form.getFieldsValue()["operator_id"] ? (
                                <CheckCircleOutlined
                                  style={{ color: "green", fontSize: 18 }}
                                />
                              ) : (
                                <CloseCircleOutlined
                                  style={{ color: "red", fontSize: 18 }}
                                />
                              )
                            }
                          >
                            {Operators.map((item) => (
                              <Select.Option
                                key={item.employee_id}
                                value={item.employee_id}
                              >
                                {item.fullname}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={20}>
                      <Col xs={{ span: 24 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                        <Form.Item
                          label="Jenis Bahan Bakar"
                          name="fuel_type"
                          rules={[
                            {
                              required: true,
                              message: "Wajib Isi",
                            },
                          ]}
                        >
                          <Radio.Group>
                            <Radio value="Solar">Solar</Radio>
                            <Radio value="Benzine">Bensin</Radio>
                          </Radio.Group>
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                        <Form.Item
                          label="Jumlah diajukan"
                          name="amount_submitted"
                          rules={[
                            {
                              required: true,
                              message: "Wajib Isi",
                            },
                          ]}
                        >
                          <InputNumber
                            type={"number"}
                            onChange={handleAmountSubmittedChange}
                            step={0.1}
                            addonAfter="L"
                          />
                        </Form.Item>
                      </Col>
                      <Col xs={{ span: 24 }} sm={{ span: 7 }} xl={{ span: 7 }}>
                        <Form.Item
                          label="Jumlah dikeluarkan"
                          name="amount_issued"
                          rules={[
                            {
                              required: true,
                              message: "Wajib Isi",
                            },
                          ]}
                        >
                          <InputNumber
                            type={"number"}
                            step={0.1}
                            addonAfter="L"
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={20}>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 16 }}
                        xl={{ span: 16 }}
                      >
                        <Form.Item
                          label="Catatan Penyesuaian"
                          name="adjustment_note"
                          rules={[
                            {
                              required: true,
                              message: "Wajib Isi dengan minimal 20 karakter",
                              min: 20,
                            },
                          ]}
                        >
                          <Input.TextArea rows={2} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </div>
            </div>
            <div className="col-md-12">
              {RequestDetail && (
                <Collapse
                  className="collapse_sr"
                  expandIconPosition={"end"}
                  accordion={false}
                  bordered={true}
                  defaultActiveKey={["0", "1", "2", "3", "4", "5"]}
                >
                  <Panel
                    style={{ background: "#fce8b6" }}
                    header={<b>Attachments</b>}
                    key="2"
                  >
                    <div className="row">
                      <div className="col">
                        <label
                          style={{ marginBottom: "10px", display: "block" }}
                        >
                          Flow Meter
                        </label>
                        {RequestDetail.record_id && (
                          <Image
                            width={100}
                            src={
                              "https://farms-fms-flowmeter.s3.ap-southeast-3.amazonaws.com/" +
                              RequestDetail.record_id
                            }
                            fallback={"https://via.placeholder.com/201"}
                          />
                        )}
                      </div>
                      <div className="col">
                        <label
                          style={{ marginBottom: "10px", display: "block" }}
                        >
                          Vehicle Meter
                        </label>
                        {RequestDetail.record_id && (
                          <Image
                            width={100}
                            src={
                              "https://farms-fms-vehicle-meter.s3.ap-southeast-3.amazonaws.com/" +
                              RequestDetail.record_id
                            }
                            fallback={"https://via.placeholder.com/200"}
                          />
                        )}
                      </div>
                      <div className="col">
                        <label
                          style={{ marginBottom: "10px", display: "block" }}
                        >
                          Operator
                        </label>
                        {RequestDetail.record_id && (
                          <Image
                            width={100}
                            src={
                              "https://farms-fms-operator.s3.ap-southeast-3.amazonaws.com/" +
                              RequestDetail.record_id
                            }
                            fallback={"https://via.placeholder.com/200"}
                          />
                        )}
                      </div>
                      <div className="col">
                        <label
                          style={{ marginBottom: "10px", display: "block" }}
                        >
                          Operator
                        </label>
                        {RequestDetail.record_id && (
                          <Image
                            width={100}
                            src={
                              "https://farms-fms-vehicle.s3.ap-southeast-3.amazonaws.com/" +
                              RequestDetail.record_id
                            }
                            fallback={"https://via.placeholder.com/200"}
                          />
                        )}
                      </div>
                    </div>
                  </Panel>
                </Collapse>
              )}
            </div>
            <div className="row mt-4">
              <Row gutter={[24, 24]}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }}>
                  <Affix offsetBottom={10}>
                    <Form.Item className="text-center">
                      <Button
                        type="primary"
                        className="m-3"
                        size="middle"
                        htmlType="submit"
                        icon={<CheckOutlined />}
                      >
                        Save Adjustment
                      </Button>
                    </Form.Item>
                  </Affix>
                </Col>
              </Row>
            </div>
          </div>
        </Form>
      </div>

      <Footer></Footer>
    </>
  );
}

const reduxState = (state) => ({
  getUser: state.initializeTicket,
});

export default connect(reduxState, null)(RequestFuelAdjustment);
